function AboutPage() {
  return (
    <div>
      <h1>AboutPage</h1>
      <p>Cool beans 🫘</p>
    </div>
  );
}

export default AboutPage;
