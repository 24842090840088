function HomePage() {
  return (
    <div>
      <h1>HomePage</h1>
      <p>My amazing home page 😊</p>
    </div>
  );
}

export default HomePage;
